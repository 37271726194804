@import '../../../styles/colors';

.task-list {
  position: relative;
  border: 1px solid $grey;
  max-height: 250px;
  overflow: auto;
  margin: 0;
  margin-bottom: 10px;
  list-style: none;
  padding: 0;
}

.task-list-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  select {
    width: auto;
  }
}

.task-list-facilitation-flag-filter {
  margin: 0 20px;
  button {
    margin-left: 10px;
  }
}
