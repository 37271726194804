.item {
  display: flex;
  align-items: center;
}

.item .value {
  margin-left: 10px;
}

.task-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 290px;
}

.student-item-detail__left, .student-item-detail__right {
  height: 290px;
}
