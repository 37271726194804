.modal-background {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modal-wrapper {
  position: absolute;
  background: white;
  min-height: 400px;
  min-width: 600px;
  max-width: 80%;
  max-height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-content {
  padding: 20px 50px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}