@import '../../../styles/colors';

.task {
  background: white;
  position: relative;
  border-bottom: 1px solid $grey;
  cursor: pointer;
  display: flex;
  transition: background-color 0.3s;
  &:hover {
    background-color: $light-highlight;
  }
}

.task-details-container {
  width: 65%;
  padding: 10px;
  min-height: 100%;
}
.task-details {
  justify-content: space-between;
}

.task-notes {
  width: 100%;

  textarea {
    width: 100%;
    height: 100%;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    font-size: 13px;
  }
}

.task--hide-border {
  border-color: transparent;
}

.task--planned {
  background-color: $highlight;
}

.task--static {
  background-color: #ffffff;
}

.task-name {
  display: inline-block;
  position: relative;
  font-size: 13px;
  font-weight: bold;
  padding-left: 30px;
  margin-bottom: 10px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: $grey;
    border-radius: 100%;
  }
  &.task-status--Revise {
    &::before {
      background: $purple;
    }
  }
  &.task-status--Complete {
    &::before {
      background: #359d33;
    }
  }
  &.task-status--NeedHelp {
    &::before {
      background: #ffcd53;
    }
  }
  &.task-status--Submitted {
    &::before {
      background: $blue;
    }
  }
}

.task-competencies {
  font-size: 12px;
}

.task-actions {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
