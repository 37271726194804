@import '../styles/colors';

.create-group {
  display: flex;
  > div {
    padding: 20px 20px 20px 0;
    width: 33%;
    min-height: 300px;
    &:last-of-type {
      padding-right: 0;
    }
  }
  border-bottom: 1px solid $grey;
}

.group-task,
.matching-students-list__item {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    color: #999;
    margin: 0;
    padding: 0;
    font-size: 12px;
    &:before {
      content: '- ';
    }
  }
}

.create-group-notes__wrapper {
  display: flex;
  padding: 20px 0;
}
.create-group-notes {
  width: 66%;
  textarea {
    width: 100%;
    height: 200px;
  }
}
.create-group-buttons {
  width: 33%;
  padding: 20px;
}

.matching-students-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid $grey;
  max-height: 320px;
  overflow: auto;
}

.matching-students-list__item {
  width: 100%;
  position: relative;
  display: block;
  margin: 0;
  text-align: left;
  cursor: pointer;
  padding: 10px 10px 10px 10px;
  font-size: 16px;
  button {
    text-align: left;
  }
  border-bottom: 1px solid $grey;
}
.matching-students-list__task {
  font-size: 12px;
}
.matching-students-list__item--selected {
  background: $highlight;
  //   &:before {
  //     content: '\2713';
  //     font-size: 25px;
  //     font-weight: bold;
  //     position: absolute;
  //     top: 50%;
  //     left: 5px;
  //     transform: translateY(-50%);
  //   }
}
