@import './styles/_colors.scss';

* {
  box-sizing: border-box;
  line-height: 1.3rem;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: $black;
}

button {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: sans-serif;
  font-size: inherit;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  outline: none;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

label {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

textarea {
  border-color: $grey;
  resize: none;
  font-size: .75rem;
  padding: 10px;
}
a {
  color: $blue;
}

.page-wrapper {
  padding: 0 20px;
}

.page-wrapper__inner {
  max-width: 1440px;
  margin: auto;
}

.nav-wrapper {
  background-color: $blue;
  padding: 15px 20px 15px 20px;
}
.nav-wrapper--secondary {
  background-color: $light-grey;
  border-bottom: 1px solid $grey;
  margin-bottom: 40px;
}

.primary-nav {
  max-width: 1440px;
  margin: auto;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    display: inline-block;
    padding-bottom: 2px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    &.is-active {
      color: white;
      border-bottom: 2px solid white;
    }
  }
}

.secondary-nav {
  max-width: 1440px;
  margin: auto;
  color: $blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    display: inline-block;
    padding-bottom: 2px;
    margin: 0 5px;
    text-decoration: none;
    font-size: 16px;
    line-height: 1;
    &.is-active {
      border-bottom: 2px solid $blue;
    }
  }
}

.button-white {
  text-decoration: none;
  color: $blue;
  background: white;
  padding: 0.55rem 1rem;
  border-radius: 5px;
  border: 1px solid $grey;
  font-weight: bold;

  &:hover {
    background: $light-grey;
  }
}
.button-blue {
  text-decoration: none;
  color: $black;
  font-weight: bold;
  background: $blue;
  color: white;
  padding: 0.55rem 1rem;
  border-radius: 5px;
  border: 1px solid $blue;

  &:hover {
    background: $light-blue;
  }
}

.button-link {
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  color: $blue;
}

.input,
.select {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #ccc;
  color: #555;
  border-radius: 5px;
}
.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select::-ms-expand {
  display: none;
}
.select:hover {
  border-color: #888;
}
.select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select option {
  font-weight: normal;
}

.input-group {
  display: flex;
  align-items: center;
  .input {
    display: inline-block;
    width: auto;
    border-radius: 5px 0 0 5px;
    line-height: 1.6;
  }
  .button-white {
    border-radius: 0px 5px 5px 0;
    border-left: none;
  }
}

table {
  width: 100%;
  border-spacing: 5px;
}

th,
td {
  text-align: left;
  padding: 15px 15px 15px 0;
}

table,
th,
td {
  border-bottom: 1px solid $grey;
  border-collapse: collapse;
  vertical-align: top;
}

.fa-1x {
  font-size: 1.25rem;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}