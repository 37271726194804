@import '../../../styles/colors';

.student-item {
  display: flex;
  flex-wrap: nowrap;
}

.student-item__wrapper {
  position: relative;
  margin: 15px 0 0 0;
  &:first-of-type {
    margin: 5px 0 0 0;
  }
}

.student-item__box {
  background: $light-grey;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  border-left: 1px solid $grey;
  border-right: 1px solid $grey;
  padding: 10px 10px 10px 20px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  cursor: pointer;
}

.student-item__student {
  width: 20%;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.student-item__name,
.student-item__tasks {
  margin-bottom: 5px;
}
.student-item__name {
  font-size: 18px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.student-item__tasks {
  font-size: 12px;
  line-height: 1.2;
  margin-top: 4px;
}
.student-item__courses {
  width: 25%;
  padding-right: 5px;
  list-style: none;
  font-size: 14px;
  padding-left: 0;
  margin: 0;
  line-height: 1.3rem;
}
.student-item__course {
  text-align: left;
  color: $dark-grey;
  outline: none;
  font-weight: normal;
}
.student-item__course--selected {
  color: $blue;
}

.student-item__course--selected.student-item__course--focus {
  color: $orange;
}

.student-item__course--focus-credit {
  color: #ff6600
}

.student-item__plan {
  width: 55%;
}

.student-item__plan-task {
  background: white;
  border: 1px dashed $grey;
}
.student-item__plan-task:not(:last-child) {
  margin-bottom: 10px;
}
.student-item__plan-conference {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background: white;
  border: 1px dashed $grey;
  padding: 10px;
}
.student-item__plan-conference-actions-edit,
.student-item__plan-conference-actions-delete {
  padding:1px;
}
.student-item__plan-conference-actions-edit {
  margin-right: 5px;
}
.student-item__plan-conference-title {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.student-item__plan-conference-date {
  font-weight: bold;
}

.student-item__plan-conference-description {
  margin-top: 5px;
}

.student-item__unplanned {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $grey;
}

.student-item__focus-credit {
  .fa-1x {
    width: 0.6em;
    height: 1em;
    display: inline-block;
    margin-right: 5px;
    padding-top: 5px;
  }
}

.traffic-light {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 700;
  color: white;
  margin-right: 10px;
}
.traffic-light--red {
  background-color: $red;
}

.traffic-light--green {
  background-color: $green;
}
.traffic-light--yellow {
  background-color: $yellow;
  color: $black;
}
.student-item__notes {
  position: relative;
  width: 25%;
  padding-left: 10px;
  textarea {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 14px;
  }
}

.student-list__header {
  margin-top: 20px;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 10px 10px 20px;
  div:nth-child(1) {
    width: 20%;
  }
  div:nth-child(2) {
    width: 25%;
  }
  div:nth-child(3) {
    width: 55%;
  }
}

.student-item-detail {
  max-height: 0px;
  transition: max-height 0.3s;
  background-color: $light-grey;
  border-left: 1px solid $grey;
  border-right: 1px solid $grey;
  overflow: hidden;
  h4 {
    margin-top: 0;
  }
}
.student-item-detail--show {
  max-height: 500px;
  border-bottom: 1px solid $grey;
}
.student-item-detail__inner {
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 10px 10px 20px;
  h4 {
    margin: 10px 0;
  }
  > div:nth-child(1) {
    width: 45%;
    padding-right: 10px;
  }
  > div:nth-child(2) {
    width: 55%;
    padding-right: 10px;
  }
}

.student-item-detail__notes {
  width:60%;
  min-height: 100px;
  font-size:11px;
}

.student-profile {
  margin-left: 10px;
}

.student-item__loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
}