.typeahead {
  width: 100%;
  position: relative;
}

.typeahead-list {
  display: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  border: 1px solid #ccc;
  border-top: none;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 7px 10px;
  }
  &--open {
    display: block;
  }
}

.typeahead-clear {
  font-size: 18;
  font-weight: 700;
  display: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.typeahead-clear--show {
  display: block;
}

.input.input-panel-attached {
  border-radius: 5px 5px 0 0;
}
