$black: #555555;
$light-grey: #f0f0f0;
$grey: #cccccc;
$dark-grey: #999999;
$highlight: #f1f5d3;
$light-highlight: #e9e9e9;
$red: #B30000;
$green: #00B300;
$yellow: #FFFF00;
$purple: rgb(155, 7, 155);
$blue: #0055aa;
$light-blue: #006ad5;
$orange: #ff6400;
