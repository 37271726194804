.student-conference {
    position: relative;

    textarea {
      width: 100%
    }
}

.student-conference__button {
  display: flex;
  justify-content: flex-end;
}