@import '../styles/colors';

.archived-plan-item {
  display: flex;
  justify-content: space-between;
  color: $dark-grey;
  border-bottom: 1px solid $grey;
  padding: 5px;
  height: 60px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $light-highlight;
  }

  .download-actions {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .input-plan-name {
    color: $dark-grey;
    font-size: 1.5em;
    font-weight: bold;
    padding: 5px;
  }

  .name-and-link {
    display: flex;
    align-items: center;

    .action {
      cursor: pointer;
      color: $blue;
      margin-left: 10px;
    }

    .link {
      cursor: pointer;
      line-height: 0;
      margin-left: 10px;
      svg {
        color: $blue;
      }
    }
  }
}

.item-loading-container {
    
}