.group-list {
  margin-bottom: 30px;
  input {
    width: auto;
  }
}

.group-item__actions {
  display: flex;
  justify-content: flex-end;
  button {
    display: inline-block;
    margin-left: 10px;
  }
}

.group-item__competencies {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-style: italic;
}
