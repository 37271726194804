.student-filters {
  display: flex;
}

.student-filters__left {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  align-items: self-end;
  .select,
  .typeahead {
    width: calc(33% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.student-filters__course-filter {
  width: calc(33% - 10px);
  margin-right: 10px;
}

.student-filters__course-select {
  width: 100% !important;
}

.student-filters__course-radio {
  display: flex;
  justify-content: space-between;
}

.student-filters__course-radio-label {
  margin-left: 5px;
}

.student-filters__right {
  width: 30%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
